.ip-management {
  background: var(--color-surface);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.ip-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--color-success);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.add-rule-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.add-rule-form input,
.add-rule-form select {
  padding: 8px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
}

.add-rule-form input[type="text"] {
  flex: 1;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  text-transform: capitalize;
}

.status-badge.block {
  background: var(--color-error);
  color: white;
}

.status-badge.allow {
  background: var(--color-success);
  color: white;
}

.delete-rule {
  padding: 4px 8px;
  background: var(--color-error);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dark-mode .ip-management {
  background: var(--color-darkBackground);
}

.dark-mode .add-rule-form input,
.dark-mode .add-rule-form select {
  background: var(--color-surface);
  color: var(--color-text);
  border-color: var(--color-darkBorder);
}
