.admin-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid #eee;
}

.admin-login {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
}

.login-button {
  width: 100%;
  padding: 12px;
  background: var(--color-primary);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-button:hover {
  background: var(--color-primaryDark);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.login-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Dark mode support */
.dark-mode .login-button {
  background: var(--color-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dark-mode .login-button:hover {
  background: var(--color-primaryDark);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.logout-button {
  padding: 8px 16px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.submissions-list {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

th {
  background: #f8f9fa;
  font-weight: 600;
}

.view-button {
  padding: 6px 12px;
  background: var(--color-primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error-message {
  color: #dc3545;
  margin-bottom: 15px;
  text-align: center;
}

@media (max-width: 768px) {
  .admin-dashboard {
    padding: 10px;
  }

  table {
    font-size: 14px;
  }

  th, td {
    padding: 8px;
  }
}

.dashboard-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
}

.stat-card h3 {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}

.stat-card p {
  margin: 10px 0 0;
  font-size: 1.8rem;
  font-weight: bold;
  color: #007bff;
}

.search-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.date-filter {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.bulk-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.bulk-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #007bff;
  color: white;
  cursor: pointer;
}

.bulk-actions button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Dark mode styles */
.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-mode .admin-header {
  border-bottom-color: #333;
}

.dark-mode .submissions-list,
.dark-mode .stat-card {
  background: #2d2d2d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dark-mode th {
  background: #333;
  color: #fff;
}

.dark-mode td {
  border-bottom-color: #444;
}

.dark-mode .search-input,
.dark-mode .date-filter {
  background: #333;
  border-color: #444;
  color: #fff;
}

.dark-mode .view-button {
  background: #0056b3;
}

.theme-toggle {
  padding: 8px;
  margin-right: 10px;
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  transition: all 0.3s ease;
}

.dark-mode .theme-toggle {
  border-color: #444;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Dark mode styles for forms */
.dark-mode .admin-login {
  background: #2d2d2d;
}

.dark-mode .form-group input {
  background: #333;
  border-color: #444;
  color: #fff;
}

.dark-mode .error-message {
  color: #ff6b6b;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.delete-button {
  padding: 6px 12px;
  background: var(--color-error);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background: #c82333;
  transform: translateY(-1px);
}

.delete-button:active {
  transform: translateY(0);
}

/* Dark mode support */
.dark-mode .delete-button {
  background: #dc3545;
}

.dark-mode .delete-button:hover {
  background: #bd2130;
}

/* Responsive design */
@media (max-width: 480px) {
  .action-buttons {
    flex-direction: column;
    gap: 4px;
  }

  .action-buttons button {
    width: 100%;
  }
}

.files-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.file-info {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9em;
}

.file-size {
  color: #666;
  font-size: 0.8em;
}

.device-info {
  font-size: 0.8em;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dark-mode .file-size {
  color: #999;
}

.checkbox-cell {
  width: 40px;
  text-align: center;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.dark-mode input[type="checkbox"] {
  filter: invert(1) hue-rotate(180deg);
}

.location-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.location-text {
  flex: 1;
  font-size: 0.9em;
}

.map-button {
  padding: 4px 8px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  transition: transform 0.2s ease;
}

.map-button:hover {
  transform: scale(1.1);
}

.dark-mode .map-button {
  filter: invert(1);
}

/* Update the existing styles for better layout */
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

td.location-info {
  max-width: 250px;
}
