/* Container styles */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
}

/* Title styles */
.title {
  text-align: center;
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

/* Form styles */
.timesheet-form {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

/* Capture section styles */
.capture-section {
  text-align: center;
  margin-bottom: 2rem;
}

.file-input {
  display: none;
}

.capture-button {
  display: inline-block;
  padding: 15px 30px;
  background: linear-gradient(135deg, #007AFF, #0056b3);
  color: white;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0,122,255,0.2);
  margin-bottom: 10px;
}

.capture-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0,122,255,0.3);
}

.capture-button:active {
  transform: translateY(1px);
}

/* Preview section styles */
.preview-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.preview {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.preview:hover {
  transform: scale(1.02);
}

/* PDF indicator styles */
.pdf-indicator {
  width: 100%;
  padding: 20px;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  border-radius: 10px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 600;
  color: #2c3e50;
  border: 2px solid #dee2e6;
  transition: all 0.3s ease;
}

.pdf-indicator:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

/* Submit button styles */
.submit-button {
  padding: 15px 40px;
  background: linear-gradient(135deg, #28a745, #218838);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(40,167,69,0.2);
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(40,167,69,0.3);
}

.submit-button:active {
  transform: translateY(1px);
}

.submit-button:disabled {
  background: linear-gradient(135deg, #6c757d, #5a6268);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Loading state styles */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}

.submit-button:disabled {
  animation: pulse 1.5s infinite;
}

/* Enhanced Mobile & Tablet Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 15px;
    min-height: calc(100vh - env(safe-area-inset-bottom));
    background: #ffffff; /* Simpler background for mobile */
  }

  .title {
    font-size: 1.8rem;
    margin: 1rem 0;
    padding: 0 10px;
  }

  .timesheet-form {
    padding: 1.5rem;
    margin: 0 10px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  }

  /* Larger touch targets for mobile */
  .capture-button {
    width: 85%;
    max-width: 300px;
    padding: 16px 24px;
    font-size: 1.1rem;
    margin: 10px auto;
    /* Improve touch feedback */
    -webkit-tap-highlight-color: transparent;
  }

  .capture-button:active {
    transform: scale(0.98);
    transition: transform 0.1s;
  }

  /* Improved preview for mobile */
  .preview-section {
    gap: 15px;
    width: 100%;
  }

  .preview {
    width: 100%;
    max-height: 60vh;
    object-fit: contain;
    margin: 10px 0;
    border-radius: 8px;
  }

  /* Better PDF indicator for mobile */
  .pdf-indicator {
    width: calc(100% - 20px);
    padding: 15px;
    font-size: 0.95rem;
    /* Add ellipsis for long filenames */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Enhanced submit button for mobile */
  .submit-button {
    width: 85%;
    max-width: 300px;
    padding: 16px 24px;
    font-size: 1.1rem;
    margin: 10px auto;
    /* Improve touch feedback */
    -webkit-tap-highlight-color: transparent;
  }

  .submit-button:active {
    transform: scale(0.98);
    transition: transform 0.1s;
  }

  /* Loading state improvements */
  .submit-button:disabled {
    opacity: 0.8;
    background: #6c757d;
  }
}

/* iPhone-specific adjustments */
@media (max-width: 390px) {
  .container {
    padding: 10px 5px;
  }

  .title {
    font-size: 1.6rem;
  }

  .timesheet-form {
    padding: 1rem;
    margin: 0 5px;
  }

  .capture-button,
  .submit-button {
    width: 90%;
    padding: 14px 20px;
    font-size: 1rem;
  }

  /* Adjust for notch/dynamic island */
  @supports (padding-top: env(safe-area-inset-top)) {
    .container {
      padding-top: env(safe-area-inset-top);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
}

/* Landscape mode optimizations */
@media (max-width: 900px) and (orientation: landscape) {
  .container {
    min-height: 100%;
    padding: 10px;
  }

  .title {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }

  .preview {
    max-height: 45vh;
  }

  .capture-button,
  .submit-button {
    padding: 12px 20px;
  }

  .timesheet-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
  }

  .preview-section {
    margin: 0;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .container {
    background: linear-gradient(to bottom right, #1a1a1a, #2d2d2d);
  }

  .title {
    color: #ffffff;
  }

  .timesheet-form {
    background: #2d2d2d;
  }

  .pdf-indicator {
    background: linear-gradient(135deg, #3a3a3a, #2d2d2d);
    border-color: #404040;
    color: #ffffff;
  }
}

/* Update header styles */
.header {
  display: flex;
  flex-direction: column;  /* Stack logo and title vertically */
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1.5rem auto 2.5rem;
  text-align: center;
}

.app-logo {
  width: 96px;  /* Make logo bigger */
  height: 96px;
  object-fit: contain;
  margin-bottom: 0.5rem;
}

/* Mobile optimizations for header */
@media (max-width: 768px) {
  .header {
    margin: 1rem auto 2rem;
  }

  .app-logo {
    width: 72px;
    height: 72px;
  }

  .title {
    font-size: 1.8rem;
  }
}

/* Add these styles to your existing CSS */
.files-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  max-height: 70vh;
  overflow-y: auto;
  padding: 10px;
}

.file-item {
  position: relative;
  width: 100%;
}

.preview-container {
  position: relative;
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
}

.remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 1;
}

.remove-button:hover {
  background: rgba(255, 0, 0, 1);
}

.pdf-container {
  position: relative;
  width: 100%;
}

.photo-number {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9rem;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .files-list {
    max-height: 60vh;
  }

  .capture-button {
    width: 90%;
    padding: 15px;
    font-size: 1.1rem;
  }

  .photo-number {
    font-size: 0.8rem;
    padding: 4px 8px;
  }
}

/* Install button styles */
.install-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 90%;
  max-width: 300px;
  margin: 0 auto 20px;
  padding: 12px 24px;
  background: linear-gradient(135deg, #6366f1, #4f46e5);
  color: white;
  border: none;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(99, 102, 241, 0.3);
  position: relative;
  overflow: hidden;
}

.install-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2)
  );
  transform: translateX(-100%);
  transition: transform 0.6s;
}

.install-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(99, 102, 241, 0.4);
}

.install-button:hover::before {
  transform: translateX(100%);
}

.install-button:active {
  transform: translateY(1px);
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .install-button {
    background: linear-gradient(135deg, #818cf8, #6366f1);
  }
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .install-button {
    font-size: 0.95rem;
    padding: 10px 20px;
  }
} 
