.color-picker {
  background: var(--color-background);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.color-group {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
}

.color-group h4 {
  margin: 0 0 15px 0;
  color: var(--color-text);
  font-size: 1rem;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 8px;
}

.color-options {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.color-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 6px;
  background: rgba(0,0,0,0.02);
}

.color-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.color-option input[type="color"] {
  width: 50px;
  height: 30px;
  padding: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reset-button {
  padding: 4px 8px;
  background: var(--color-accent);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.2s ease;
}

.reset-button:hover {
  background: var(--color-accentDark);
}

/* Dark mode styles */
.dark-mode .color-picker {
  background: var(--color-darkBackground);
}

.dark-mode .color-group {
  border-color: var(--color-darkBorder);
}

.dark-mode .color-group h4 {
  color: var(--color-darkText);
  border-bottom-color: var(--color-darkBorder);
}

.dark-mode .color-option {
  background: rgba(255,255,255,0.05);
}

.dark-mode .color-option label {
  color: var(--color-darkText);
}

/* Responsive design */
@media (max-width: 768px) {
  .color-options {
    grid-template-columns: 1fr;
  }
  
  .color-group {
    padding: 10px;
  }
} 

/* Add these styles to your existing ColorPicker.css */
.color-picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.save-button {
  padding: 8px 16px;
  background: var(--color-success);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
}

.save-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.save-button:active {
  transform: translateY(0);
}

.dark-mode .save-button {
  background: var(--color-success);
} 
